// PromoBanner.js
import { Box } from '@mui/material';

const PromoBanner = () => (
  <Box
    sx={{
      width: '100%',
      bgcolor: '#f9f9f9',
      color: '#08354c',
      padding: '8px 16px',
      textAlign: 'center',
      fontSize: '0.9rem',
      borderBottom: '1px solid #c6c6c6',
    }}
  >
    Besoin de papier d'identité physique ou scan :{' '}
    <a
      href="https://onedake.io"
      target="_blank"
      rel="noopener noreferrer"
      className="social-link"
    >
      {'OneDake.io'}
    </a>
  </Box>
);

export default PromoBanner;
